import { createRouter, createWebHistory } from 'vue-router'
import { isAuth,getTokenDecoden,renewToken } from '@/helpers/tokenauth.js';
import Swal from "sweetalert2";

const routes = [{
        path: '/',
        name: 'Home',
        meta:{requiresAuth: false},
        children: [
            {
                path: '',
                component: () => import ('../views/Home.vue'),
            },
            {
                path: ':organization',
                children: [
                    {
                        path: '',
                        component: () => import ('../views/SINAPTISSSTE/Home.vue')
                    },
                    {
                        path: 'registro',
                        component: () => import ('../views/RegistroCliente.vue')
                    }
                ]
            }
        ]
    },
    {
        path: '/registro',
        name: 'RegistroCliente',
        meta:{requiresAuth: false},
        component: () =>
            import ('../views/RegistroCliente.vue')
    },
    {
        path: '/terminos-y-condiciones/:state/:city?/:rfc/:paternal/:maternal/:firstName/:lastName/:cellphone/:email/:password/:typeReference?/:accountType?/:apiToken',
        path: '/terminos-y-condiciones/',
        name: 'TerminosYCondiciones',
        component: () =>
            import ('../views/Cuenta/TerminosYCondiciones.vue')
    },
    {
        path: '/contacto',
        name: 'FormularioContacto',
        meta:{requiresAuth: false},
        component: () =>
            import ('../views/FormularioContacto.vue')
    },
    {
        path: '/login',
        name: 'Login',
        meta:{requiresAuth: false},
        component: () =>
            import ('../views/Login.vue')
    },
    {
        path: '/recuperar-contrasena',
        name: 'Recuperar contraseña',
        meta:{requiresAuth: false},
        component: () =>
            import ('../views/RecuperarContrasena.vue')
    },
    {
        path: '/cambiar-contrasena',
        name: 'Cambiar contraseña',
        meta:{requiresAuth: false},
        component: () =>
            import ('../views/CambiarContrasena.vue')
    },
    {
        path: "/cuenta/principal",
        hidden: true,
        name: 'PrincipalCuenta',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/Principal.vue')
    },
    {
        path: "/cuenta/simulador",
        hidden: true,
        name: 'Simulador',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/SimuladorAhorro.vue')
    },
    {
        path: "/cuenta/cambiar-contrasena",
        hidden: true,
        name: 'Cambiar contraseña cuenta',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/CambiarContrasena.vue')
    },
    {
        path: "/cuenta/datos/faltantes/:category",
        hidden: true,
        name: 'Actualizar datos personales cuenta',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/UpdatePersonalData.vue')
    },
    {
        path: "/cuenta/completar-registro",
        hidden: true,
        name: 'Completar registro',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        //component: () => import('../views/Cuenta/CompletarRegistro.vue')
        component: () =>
            import ('../views/Cuenta/RegistroDatosPersonales.vue')
    },
    {
        path: "/cuenta/ahorro",
        hidden: true,
        name: 'Ahorro',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/Ahorro.vue')
    },
    {
        path: "/cuenta/registro-datos-personales",
        hidden: true,
        name: 'Registro datos personales',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/RegistroDatosPersonales.vue')
    },
    {
        path: "/cuenta/registro-datos-personales-dos",
        hidden: true,
        name: 'Registro datos personales dos',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/RegistroDatosPersonalesDos.vue')
    },
    {
        path: "/cuenta/beneficiarios",
        hidden: true,
        name: 'Beneficiarios',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/Beneficiarios.vue')
    },
    {
        path: "/cuenta/informacion-economica",
        hidden: true,
        name: 'Informacion economica',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/InformacionEconomica.vue')
    },
    {
        path: "/cuenta/informacion-laboral",
        hidden: true,
        name: 'Informacion laboral',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/InformacionLaboral.vue')
    },
    {
        path: "/cuenta/carga-documentos/:fromMenu",
        hidden: true,
        name: 'Carga de Documentos',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/CargaDocumentos.vue')
    },
    {
        path: "/admin/principal",
        hidden: true,
        name: 'PrincipalAdmin',
        meta:{requiresAuth: true,roles:["administrator"]},
        component: () =>
            import ('../views/Admin/Principal.vue')
    },
    {
        path: "/:role/cambiar-contrasena",
        hidden: true,
        name: 'Cambiar contraseña administrador',
        meta:{requiresAuth: true,roles:["administrator","member_analyst","loan_analyst","supervisor","enroller"]},
        component: () =>
            import ('../views/Administrative/CambiarContrasena/CambiarContrasena.vue')
    },
    {
        path: "/:role/generar-referencias",
        hidden: true,
        name: 'Generar Referencias',
        meta:{requiresAuth: true,roles:["administrator"]},
        component: () =>
            import ('../views/Administrative/ConfigurarReferencias/ConfigurarReferencias.vue')
    },
    {
        path: "/:role/reportes/carga-itbanks",
        hidden: true,
        name: 'Carga ITBanks',
        meta:{requiresAuth: true,roles:["administrator","member_analyst","loan_analyst","supervisor"]},
        component: () =>
            import ('../views/Administrative/Reports/CargaITBanks.vue')
    },
    {
        path: "/analyst/principal",
        hidden: true,
        name: 'Principal',
        meta:{requiresAuth: true,roles:["member_analyst","loan_analyst"]},
        component: () =>
            import ('../views/Analyst/PaginaPrincipal.vue')
    },
    {
        path: "/analyst/admin/solicitudes-ingreso",
        hidden: true,
        name: 'PrincipalAdminSolicitudIngreso',
        component: () =>
            import ('../views/Analyst/Admin/SolicitudesIngreso.vue')
    },
    {
        path: "/analyst/solicitudes-en-proceso",
        hidden: true,
        name: 'PrincipalSolicitudEnProceso',
        meta:{requiresAuth: true,roles:["member_analyst","loan_analyst"]},
        component: () =>
            import ('../views/Analyst/SolicitudesEnProceso.vue')
    },
    {
        path: "/analyst/cuenta/solicitudes-ingreso",
        hidden: true,
        name: 'PrincipalCuentaSolicitudIngreso',
        meta:{requiresAuth: true,roles:["member_analyst","loan_analyst"]},
        component: () =>
            import ('../views/Analyst/Cuenta/SolicitudesIngreso.vue')
    },
    {
        path: "/analyst/cuenta/revision-solicitudes-ingreso/:requestId",
        hidden: true,
        name: 'PrincipalCuentaRevisionSolicitudIngreso',
        meta:{requiresAuth: true,roles:["member_analyst","loan_analyst"]},
        component: () =>
            import ('../views/Analyst/Cuenta/RevisionSolicitudesIngreso.vue')
    },
    {
        path: "/:role/pagos/inscripcion",
        hidden: true,
        name: 'SolicitudesPagosInscripcion',
        meta:{requiresAuth: true,roles:["member_analyst","loan_analyst", "supervisor"]},
        children: [
            {
                path: '',
                component: () => import ('../views/Administrative/Payments/Registration/RegistrationPaymentRequests.vue')
            },
            {
                path: '/',
                component:() => import ('../views/Administrative/Payments/Registration/RegistrationPaymentRequests.vue')
            },
            {
                path: 'categoria/:category',
                component: () => import ('../views/Administrative/Payments/Registration/RegistrationPaymentRequestsNew.vue')
            },
            {
                path: 'firmados',
                component:() => import ('../views/Administrative/Payments/Registration/RegistrationPaymentRequestsWithSignature.vue')
            }
        ]
    },
    {
        path: "/:role/pagos/aportacion",
        hidden: true,
        name: 'SolicitudesPagosaportacion',
        meta:{requiresAuth: true,roles:["member_analyst","loan_analyst", "supervisor"]},
        children: [
            {
                path: '',
                component: () => import ('../views/Administrative/Payments/Contribution/RequestsContributionPayment.vue')
            },
            {
                path: '/',
                component:() => import ('../views/Administrative/Payments/Contribution/RequestsContributionPayment.vue')
            },
            {
                path: 'categoria/:category',
                component: () => import ('../views/Administrative/Payments/Contribution/RequestsContributionPaymentNew.vue')
            },
            {
                path: 'firmados',
                component:() => import ('../views/Administrative/Payments/Contribution/RequestsContributionPaymentWithSignature.vue')
            }
        ]
    },
    {
        path: "/cuenta/prestamo/principal/:step?/:form?/:showBackButton?",
        hidden: true,
        name: 'PrincipalCuentaPrestamoPrincipal',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/Prestamo/Prestamo.vue')
    },
    {
        path: "/cuenta/prestamo/declaracion/:step?/:form?/:showBackButton?",
        hidden: true,
        name: 'PrincipalCuentaPrestamoDeclarancion',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/Prestamo/Declaracion.vue')
    },
    {
        path: "/cuenta/prestamo/proveedor-recursos/:step?/:form?/:showBackButton?",
        hidden: true,
        name: 'PrincipalCuentaPrestamoProveedorRecursos',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/Prestamo/ProveedorRecursos.vue')
    },
    {
        path: "/analyst/cuenta/solicitudes-aceptadas",
        hidden: true,
        name: 'PrincipalCuentaSolicitudesAceptadas',
        meta:{requiresAuth: true,roles:["member_analyst","loan_analyst"]},
        component: () =>
            import ('../views/Analyst/Cuenta/SolicitudesAceptadas.vue')
    },
    {
        path: "/analyst/cuenta/solicitudes-rechazadas",
        hidden: true,
        name: 'PrincipalCuentaSolicitudesRechazadas',
        meta:{requiresAuth: true,roles:["member_analyst","loan_analyst"]},
        component: () =>
            import ('../views/Analyst/Cuenta/SolicitudesRechazadas.vue')
    },
    {
        path: '/cuenta/aviso-privacidad-autorizo-descuento',
        hidden: true,
        name: 'AvisoPrivacidadAutorizoDescuento',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/AvisoPrivacidadAutorizoDescuento.vue')
    },
    {
        path: '/cuenta/previsualizacion-autorizo-descuento',
        hidden: true,
        name: 'PrevisualizacionAutorizoDescuento',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/PrevisualizacionAutorizoDescuento.vue')
    },
    {
        path: "/cuenta/informacion-laboral-miembros",
        hidden: true,
        name: 'Informacion laborales miembros',
        component: () =>
            import ('../views/Cuenta/InformacionLaboralMiembros.vue')
    },
    {
        path: "/cuenta/video/:process_name",
        hidden: true,
        name: 'Video contrato',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/Video.vue')
    },
    {
        path: "/analyst/solicitudes-incompletas",
        hidden: true,
        name: 'Reporte de solicitudes incompletas',
        meta:{requiresAuth: true,roles:["member_analyst","loan_analyst"]},
        component: () =>
            import ('../views/Analyst/Cuenta/ReporteSolicitudesIncompletas.vue')
    },
    {
        path: "/cuenta/referidos/referir-persona",
        hidden: true,
        name: 'Referir persona',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/Referidos/ReferPerson.vue')
    },
    {
        path: "/cuenta/referidos/referencia-exitosa",
        hidden: true,
        name: 'Referencia exitosa',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/Referidos/SuccessfulReferral.vue')
    },
    {
        path: "/cuenta/referidos",
        hidden: true,
        name: 'Referencias',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/Referidos/Referrals.vue')
    },
    {
        path: "/cuenta/referidos/historial/:type",
        hidden: true,
        name: 'Historial',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/Referidos/Historial.vue')
    },
    {
        path: "/cuenta/pago-parte-social/upload-voucher",
        hidden: true,
        name: 'UploadVoucher',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/PagoParteSocial/UploadVoucher.vue')
    },
    {
        path: "/cuenta/pago-parte-social/pago-suscripcion",
        hidden: true,
        name: 'Pago parte social',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/PagoParteSocial/PagoSuscripcion.vue')
    },
    {
        path: "/cuenta/pago-parte-social/pago-exitoso",
        hidden: true,
        name: 'Pago parte social exitoso',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/PagoParteSocial/PagoExitoso.vue')
    },
    {
        path: "/cuenta/pago-parte-social/referencia-de-pago",
        hidden: true,
        name: 'ReferenciaDePago',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/PagoParteSocial/ReferenciaDePago.vue')
    },
    {
        path: "/cuenta/pago-parte-social/comprobante-adjuntado-exitosamente",
        hidden: true,
        name: 'ComprobanteAdjuntadoExitosamente',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/PagoParteSocial/ComprobanteAdjuntadoExitosamente.vue')
    },
    {
        path: "/cuenta/pago-parte-social/elegir-forma-pago",
        hidden: true,
        name: 'ElegirFormaPago',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/PagoParteSocial/ElegirFormaDePago.vue')
    },
    {
        path: "/cuenta/pago-parte-social/pago-banco-transferencia",
        hidden: true,
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        name: 'PagoBancoTransferencia',
        component: () =>
            import ('../views/Cuenta/PagoParteSocial/PagoEnBanco.vue')
    },
    {
        path: "/pago-exitoso",
        hidden: true,
        name: 'pago exitoso',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/PagoExitoso.vue')
    },
    {
        path: "/educacion-financiera",
        hidden: true,
        name: 'Educacion Financiera',
        meta:{requiresAuth: false},
        component: () =>
            import ('../views/EducacionFinanciera.vue')
    },
    {
        path: "/prestamo-simple",
        hidden: true,
        name: 'Prestamo Simple',
        meta:{requiresAuth: false},
        component: () =>
            import ('../views/PrestamoSimple.vue')
    },
    {
        path: "/aviso-de-fraude",
        hidden: true,
        name: 'Aviso de Fraude',
        component: () =>
            import ('../views/AvisoDeFraude.vue')

    },
    {
        path: "/educacion-cooperativa",
        hidden: true,
        name: 'Educacion Cooperativa',
        meta:{requiresAuth: false},
        component: () =>
            import ('../views/EducacionCooperativa.vue')
    },
    {
      path: "/cuenta/informacion-laboral-cliente-regular",
      hidden: true,
      name: 'Informacion laboral cliente regular',
      meta:{requiresAuth: true,roles:["member","cabsa_member"]},
      component: () => import('../views/Cuenta/InformacionLaboralClienteRegular.vue')
    },
    {
      path: "/personal/registro",
      hidden: true,
      name: 'Registro personal',
      component: () => import('../views/Staff/RegistrationPersonal.vue')
    },
    {
        path: "/personal/login",
        hidden: true,
        name: 'Login personal',
        meta:{requiresAuth: false},
        component: () => import('../views/Staff/LoginPersonal.vue')
    },
    /*Captura asistida*/
    {
        path: "/:rol/captura-asistida/seccion",
        hidden: true,
        meta:{requiresAuth: true,roles:["member","cabsa_member","administrator","enroller","loan_analyst","member_analyst","supervisor"]},
        children: [
            {
                path: 'uno',
                name: 'CapturaAsistida',
                component: () => import ('../views/Administrative/AssistedEnrollment/FONCABSA/AssistedEnrollmentStep1.vue')
            },
            {
                path: 'dos',
                name: 'CapturaAsistidaSeccionDos',
                component: () => import ('../views/Administrative/AssistedEnrollment/FONCABSA/AssistedEnrollmentStep2.vue')
            },
        ]
    },
    {
        path: "/:rol/captura-asistida/sinaptissste/seccion",
        hidden: true,
        meta:{requiresAuth: true,roles:["issste_enroller","member","cabsa_member","administrator","enroller","loan_analyst","member_analyst","supervisor"]},
        children: [
            {
                path: 'uno',
                name: 'CapturaAsistidasinaptissste',
                component: () => import ('../views/Administrative/AssistedEnrollment/SINAPTISSSTE/AssistedEnrollmentStep1.vue')
            },
            {
                path: 'dos',
                name: 'CapturaAsistidasinaptisssteSeccionDos',
                component: () => import ('../views/Administrative/AssistedEnrollment/SINAPTISSSTE/AssistedEnrollmentStep2.vue')
            },
        ]
    },
    {
        path: "/adviser/principal",
        hidden: true,
        name: 'AdviserPrincipal',
        component: () =>
            import ('../views/Advisers/Principal.vue')
    },
    {
        path: "/analyst/cuenta/cargar-documentos/:requestId",
        hidden: true,
        name: 'Cargar documentos',
        meta:{requiresAuth: true,roles:["member_analyst","loan_analyst"]},
        component: () => import ('../views/Analyst/Cuenta/CargaDocumentos.vue')
    },
    {
        path: "/analyst/cuenta/cargar-documentos-dinamica/:requestId",
        hidden: true,
        name: 'Cargar documentos dinamica',
        meta:{requiresAuth: true,roles:["member_analyst","loan_analyst"]},
        component: () => import ('../views/Analyst/Cuenta/CargaDocumentosDinamica.vue')
    },
    {
        path: "/analyst/pago-parte-social",
        hidden: true,
        name: 'Pago parte social',
        meta:{requiresAuth: true,roles:["member_analyst","loan_analyst"]},
        component: () => import ('../views/Analyst/PagoParteSocial.vue')
    },
    {
        path: "/supervisor/principal",
        hidden: true,
        name: 'Principal Supervisor',
        meta:{requiresAuth: true,roles:["supervisor"]},
        component: () =>
            import ('../views/Supervisor/PaginaPrincipal.vue')
    },
    {
        path: "/supervisor/solicitudes-en-proceso",
        hidden: true,
        name: 'Solicitudes en proceso supervisor',
        meta:{requiresAuth: true,roles:["supervisor"]},
        component: () =>
            import ('../views/Supervisor/SolicitudesEnProceso.vue')
    },
    {
        path: "/supervisor/cuenta/revision-solicitudes-ingreso/:requestId",
        hidden: true,
        name: 'Solicitudes en revision supervisor',
        meta:{requiresAuth: true,roles:["supervisor"]},
        component: () =>
            import ('../views/Supervisor/Cuenta/RevisionSolicitudesIngreso.vue')
    },
    {
        path: "/supervisor/cuenta/solicitudes-aceptadas",
        hidden: true,
        name: 'Solicitudes aceptadas supervisor',
        meta:{requiresAuth: true,roles:["supervisor"]},
        component: () =>
            import ('../views/Supervisor/Cuenta/SolicitudesAceptadas.vue')
    },
    {
        path: "/supervisor/cuenta/solicitudes-rechazadas",
        hidden: true,
        name: 'Solicitudes en rechazadas supervisor',
        meta:{requiresAuth: true,roles:["supervisor"]},
        component: () =>
            import ('../views/Supervisor/Cuenta/SolicitudesRechazadas.vue')
    },
    {
        path: "/supervisor/cuenta/solicitudes-ingreso",
        hidden: true,
        name: 'Solicitudes de ingreso supervisor',
        meta:{requiresAuth: true,roles:["supervisor"]},
        component: () =>
            import ('../views/Supervisor/SolicitudesIngreso.vue')
    },
    {
        path: "/supervisor/cuenta/solicitudes-asignadas",
        hidden: true,
        name: 'Solicitudes en asignadas supervisor',
        meta:{requiresAuth: true,roles:["supervisor"]},
        component: () =>
            import ('../views/Supervisor/Cuenta/SolicitudesAsignadas.vue')
    },
    {
        path: "/supervisor/cuenta/cargar-documentos/:requestId",
        hidden: true,
        name: 'Cargar documentos supervisor',
        meta:{requiresAuth: true,roles:["supervisor"]},
        component: () => 
            import ('../views/Supervisor/Cuenta/CargaDocumentos.vue')
    },
    {
        path: "/supervisor/cuenta/cargar-documentos-dinamica/:requestId",
        hidden: true,
        name: 'Cargar documentos dinamica supervisor',
        meta:{requiresAuth: true,roles:["supervisor"]},
        component: () => 
            import ('../views/Supervisor/Cuenta/CargaDocumentosDinamica.vue')
    },
    {
        path: "/supervisor/solicitudes-incompletas",
        hidden: true,
        name: 'Reporte de solicitudes incompletas supervisor',
        meta:{requiresAuth: true,roles:["supervisor"]},
        component: () =>
            import ('../views/Supervisor/Cuenta/ReporteSolicitudesIncompletas.vue')
    },
    {
        path: "/supervisor/pago-parte-social",
        hidden: true,
        name: 'Pago parte social',
        meta:{requiresAuth: true,roles:["supervisor"]},
        component: () => import ('../views/Supervisor/PagoParteSocial.vue')
    },
    {
        path: "/:pathMatch(.*)*",
        hidden: true,
        name: "NotFound",
        component: () => import ('../views/NotFound.vue'),
    },
    {
        path: "/:idrol/commissions/newcommissionscheme",
        hidden: true,
        name: 'NewCommissionScheme',
        meta:{requiresAuth: true,roles:["administrator"]},
        component: () =>
            import ('../views/CommissionScheme/NewCommissionScheme.vue')
    },
    {
        path: "/:idrol/commissions/capturebonusscheme",
        hidden: true,
        name: 'capturebonusscheme',
        meta:{requiresAuth: true,roles:["administrator"]},
        component: () =>
            import ('../views/CommissionScheme/CaptureBonusScheme.vue')
    },
    {
        path: "/:idrol/commissions/assignschemecommissions",
        hidden: true,
        name: 'asignaresquemacomisiones',
        meta:{requiresAuth: true,roles:["administrator"]},
        component: () =>
            import ('../views/CommissionScheme/AsignarEsquemaComisiones.vue')
    },
    {
        path: "/commissions/setupproductcommissionpayments",
        hidden: true,
        name: 'setupproductcommissionpayments',
        meta:{requiresAuth: true,roles:["administrator"]},
        component: () =>
            import ('../views/CommissionScheme/SetUpProductCommissionPayments.vue')
    },
    {
        path: "/:rol/commissions/payrollreport",
        hidden: true,
        name: 'payrollreport',
        meta:{requiresAuth: true,roles:["administrator"]},
        component: () =>
            import ('../views/CommissionScheme/Payroll_report.vue')
    },
    {
        path: "/:rol/commissions/payrollreport/:id",
        hidden: true,
        name: 'payrollreportuser',
        meta:{requiresAuth: true,roles:["administrator"]},
        component: () =>
            import ('../views/CommissionScheme/Payroll_report_user.vue')
    },
    {
        path: "/commissions/recordperiodpaymentcommissions",
        hidden: true,
        name: 'RecordPeriodPaymentCommissions',
        meta:{requiresAuth: true,roles:["administrator"]},
        component: () =>
            import ('../views/CommissionScheme/RecordPeriodPaymentCommissions.vue')
    },
    {
        path: "/saving-minors",
        hidden: true,
        name: 'SavingsEnrollmentForMinors',
        component: () =>
            import ('../views/SavingsEnrollmentForMinors.vue')
    },
    {
        path: "/cuenta/saving-minors",
        hidden: true,
        name: 'SavingsForMinors',
        component: () =>
            import ('../views/Cuenta/SavingsForMinors/SavingsForMinors.vue')
    },
    {
        path: "/savings-product-information",
        hidden: true,
        name: 'SavingsProduct',
        component: () =>
            import ('../views/SavingsProduct.vue')
    },
    {
        path: "/financial-education-for-minors-information",
        hidden: true,
        name: 'FinancialEducationForMinors',
        component: () =>
            import ('../views/FinancialEducationForMinors.vue')
    },
    {
        path: "/:role/referralhistory",
        hidden: true,
        name: 'ReferralHistory',
        meta:{requiresAuth: true,roles:["loan_analyst","member_analyst","supervisor"]},
        component: () =>
            import ('../views/Referral_history.vue')
    },
    {
        path: "/:role/:name/referralhistory",
        hidden: true,
        name: 'ReferralHistorySelect',
        meta:{requiresAuth: true,roles:["loan_analyst","member_analyst","supervisor"]},
        component: () =>
            import ('../views/Referral_history_select.vue')
    },
    {
        path: "/cuenta/detalle/:category",
        name: "Detail", 
        hidden: true,
        meta: { requiresAuth: true, roles: ["member","cabsa_member"] },
        children: [
            {
                path: '',
                component:() => import ('../views/Cuenta/Detail.vue')
            },
            {
                path: '/',
                component:() => import ('../views/Cuenta/Detail.vue')
            },
            {
                path: 'solicitante',
                component:() => import ('../views/Cuenta/Quote/ApplicantQuotationDetails.vue')
            },
        ]
    },
    {
        path: "/cuenta/cotizacion/:category",
        name: "Quote", 
        hidden: true,
        meta: { requiresAuth: true, roles: ["member","cabsa_member"] },
        children: [
            {
                path: '',
                component:() => import ('../views/Cuenta/Quote/Quote.vue')
            },
            {
                path: '/',
                component:() => import ('../views/Cuenta/Quote/Quote.vue')
            },
            {
                path: 'bienvenida',
                component:() => import ('../views/Cuenta/Quote/Welcome.vue')
            },
            {
                path: 'asegurado-adicional',
                component:() => import ('../views/Cuenta/Quote/QuoteAdditionalInsurance.vue')
            },
            {
                path: 'finalizar',
                component:() => import ('../views/Cuenta/Quote/QuoteDetail.vue')
            }
        ]
    },
    {
        path: "/cuenta/emision/:category",
        name: "Issuance", 
        hidden: true,
        meta: { requiresAuth: true, roles: ["member","cabsa_member"] },
        children: [
            {
                path: '',
                component:() => import ('../views/Cuenta/Quote/Issuance.vue')
            },
            {
                path: '/',
                component:() => import ('../views/Cuenta/Quote/Issuance.vue')
            },
            {
                path: 'carga-documentos',
                component:() => import ('../views/Cuenta/Quote/UploadDocument.vue')
            },
            {
                path: 'detalle',
                component:() => import ('../views/Cuenta/Quote/IssuanceDetail.vue')
            },
            {
                path: 'finalizar',
                component:() => import ('../views/Cuenta/Quote/IssuanceFinished.vue')
            }
        ]
    },
    {
        path: "/:role/:name/referralhistory/:category/:id",
        hidden: true,
        name: 'ReferralHistorySelect',
        meta:{requiresAuth: true,roles:["loan_analyst","member_analyst","supervisor"]},
        component: () =>
            import ('../views/Referral_history_select.vue')
    },
    {
        path: "/:role/reportes/layouts/socios/historial",
        hidden: true,
        name: 'Carga ITBanks History',
        meta:{requiresAuth: true,roles:["administrator","member_analyst","loan_analyst","supervisor"]},
        component: () =>
            import ('../views/Administrative/Reports/LayoutsHistory.vue')
    },
    {
        path: "/:role/reportes/layouts/socios",
        hidden: true,
        name: 'ITBanks History',
        meta:{requiresAuth: true,roles:["administrator","member_analyst","loan_analyst","supervisor"]},
        component: () =>
            import ('../views/Administrative/Reports/Layouts.vue')
    },
    {
        path: "/:role/itbanks/configuracion/folio",
        hidden: true,
        name: 'ITBanks Configuration Folio',
        meta:{requiresAuth: true,roles:["administrator","member_analyst","loan_analyst","supervisor"]},
        component: () =>
            import ('../views/Administrative/Reports/CustomerFolioConfiguration.vue')
    },
    {
        path: "/sinaptissste-enroller/principal",
        hidden: true,
        name: 'Principalsinaptissste',
        meta:{requiresAuth: true,roles:["issste_enroller"]},
        component: () =>
            import ('../views/SINAPTISSSTE/Principal.vue')
    },
    {
        path: '/cuenta/beneficiaries',
        hidden: true,
        name: 'Beneficiaries',
        meta:{requiresAuth: true,roles:["member","cabsa_member"]},
        component: () =>
            import ('../views/Cuenta/BeneficiariesView.vue')
    },
    {
        path: "/cuenta/credito",
        hidden: true,
        name: 'Cliente Credito',
        meta:{requiresAuth: true,roles:["cabsa_member", "member"]},
        children: [
            {
                path: '',
                component: () => import ('../views/Cuenta/Credit/ApplyCredit.vue')
            },
            {
                path: '/',
                component:() => import ('../views/Cuenta/Credit/ApplyCredit.vue')
            },
            {
                path: 'datos',
                component: () => import ('../views/Cuenta/Credit/CreditData.vue')
            },
            {
                path: 'propietario',
                component: () => import ('../views/Cuenta/Credit/CreditOwner.vue')
            },
            {
                path: 'documentos',
                component: () => import ('../views/Cuenta/Credit/CreditDocuments.vue')
            },
            {
                path: 'estatus',
                component: () => import ('../views/Cuenta/Credit/CreditHistory.vue')
            }
            
        ]
    },
    {
        path: "/cuenta/update-personal-data/:component",
        hidden: true,
        name: 'cliente update data',
        meta:{requiresAuth: true,roles:["cabsa_member", "member"]},
        component: () =>
            import ('../views/Cuenta/UpdatePersonalDataView.vue')
    },
    {

        path: '/:role/clientes/credito',
        hidden: true,
        name: 'Credit',
        meta:{requiresAuth: true,roles:["member_analyst", "loan_analyst"]},
        component: () =>
            import ('../views/Administrative/Credit/CreditCustomers.vue')
    },

    {
        path: "/:role/productos",
        name: "Products",
        children: [
            {
                path: ':product',
                hidden: true,
                meta:{requiresAuth: true,roles:["administrator","loan_analyst","member_analyst","supervisor","enroller","financial_advisor"]},
                children: [
                    {   
                        path: '',
                        hidden: true,
                        meta:{requiresAuth: true,roles:["administrator","loan_analyst","member_analyst","supervisor","enroller","financial_advisor"]},
                        component:() => import ('../views/Administrative/Products/CustomerSearch.vue')
                    },
                    {   
                        path: '/',
                        hidden: true,
                        meta:{requiresAuth: true,roles:["administrator","loan_analyst","member_analyst","supervisor","enroller","financial_advisor"]},
                        component:() => import ('../views/Administrative/Products/CustomerSearch.vue')
                    },
                    {
                        path: 'cotizacion',
                        hidden: true,
                        meta:{requiresAuth: true,roles:["administrator","loan_analyst","member_analyst","supervisor","enroller","financial_advisor"]},
                        children: [
                            {
                                path: 'cliente',
                                hidden: true,
                                meta:{requiresAuth: true,roles:["administrator","loan_analyst","member_analyst","supervisor","enroller","financial_advisor"]},
                                children: [
                                    {
                                        path: '',
                                        component:() => import ('../views/Shared/Products/Quote/RecruimentData.vue')
                                    },
                                    {
                                        path: 'contratacion',
                                        component:() => import ('../views/Shared/Products/Quote/RecruimentData.vue')
                                    },
                                    {
                                        path: 'contacto',
                                        component:() => import ('../views/Shared/Products/Quote/ContactInformation.vue')
                                    },
                                    {
                                        path: 'datos-personales',
                                        component:() => import ('../views/Shared/Products/Quote/PersonalData.vue')
                                    },
                                    {
                                        path: 'enfermedades',
                                        component:() => import ('../views/Shared/Products/Quote/Diseases.vue')
                                    },
                                    {
                                        path: 'deportes',
                                        component:() => import ('../views/Shared/Products/Quote/Sports.vue')
                                    },
                                    {
                                        path: 'ocupacion',
                                        component:() => import ('../views/Shared/Products/Quote/Occupation.vue')
                                    },
                                    {
                                        path: 'operaciones',
                                        component:() => import ('../views/Shared/Products/Quote/MedicalOperations.vue')
                                    },
                                    {
                                        path: 'pasatiempos',
                                        component:() => import ('../views/Shared/Products/Quote/Hobbies.vue')
                                    },
                                    {
                                        path: 'fuma',
                                        component:() => import ('../views/Shared/Products/Quote/Smokes.vue')
                                    },
                                    {
                                        path: 'alcohol',
                                        component:() => import ('../views/Shared/Products/Quote/Alcohol.vue')
                                    },
                                    {
                                        path: 'cobertura',
                                        component:() => import ('../views/Shared/Products/Quote/Coverage.vue')
                                    },
                                    {
                                        path: 'detalle',
                                        component:() => import ('../views/Shared/Products/Quote/ApplicantQuotationDetails.vue')
                                    },
                                    {
                                        path: 'cotizacion',
                                        component:() => import ('../views/Shared/Products/Quote/QuoteDetail.vue')
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'emision',
                        hidden: true,
                        meta:{requiresAuth: true,roles:["administrator","loan_analyst","member_analyst","supervisor","enroller"]},
                        children: [
                            {
                                path: '',
                                hidden: true,
                                component:() => import ('../views/Shared/Products/Issuance/Address.vue')
                            },
                            {
                                path: '/',
                                hidden: true,
                                component:() => import ('../views/Shared/Products/Issuance/Address.vue')
                            },
                            {
                                path: 'direccion',
                                hidden: true,
                                component:() => import ('../views/Shared/Products/Issuance/Address.vue')
                            },
                            {
                                path: 'informacion-laboral',
                                hidden: true,
                                component:() => import ('../views/Shared/Products/Issuance/LaborInformation.vue')
                            },
                            {
                                path: 'cobro',
                                hidden: true,
                                component:() => import ('../views/Shared/Products/Issuance/Collection.vue')
                            },
                            {
                                path: 'beneficiarios',
                                hidden: true,
                                component:() => import ('../views/Shared/Products/Issuance/Beneficiaries.vue')
                            },
                            {
                                path: 'carga-documentos',
                                hidden: true,
                                component:() => import ('../views/Shared/Products/Issuance/UploadDocument.vue')
                            },
                            {
                                path: 'detalle',
                                hidden: true,
                                component:() => import ('../views/Shared/Products/Issuance/Detail.vue')
                            },
                            {
                                path: 'finalizar',
                                hidden: true,
                                component:() => import ('../views/Shared/Products/Issuance/Finished.vue')
                            },
                            {
                                path: 'firma/exitosa/:guid',
                                hidden: true,
                                component:() => import ('../views/Shared/Products/Issuance/SuccessfulSigning.vue'),
                                beforeEnter: (to, from, next) => {
                                    const body = to.body;
                                    console.log("body: ", body);
                                   
                                    next();
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes:routes,
    scrollBehavior(to,from,savedPosition){
        return { top: 0 }
    }
})

router.beforeEach((to, from, next) => {
    /* invalidStoragesFound(to.fullPath); *///TODO: Habilitar al tener unificado los menus laterales
    const auth = isAuth(); 
    const _meta = to.meta;

    if(_meta.requiresAuth){

        if (!auth) {
            sessionStorage.clear();
            if(to.fullPath!="/" && from.name!="Home"){
                Swal.fire({
                    title: "Aviso",
                    text: "Su sesión ha expirado, favor de iniciar sesión nuevamente.",
                    icon: "warning",
                    confirmButtonColor: '#FEB72B',
                })
            }
            return next({ path: '/login' });
        }

        const _sessionStorage=getTokenDecoden();
        let _response=_meta.roles.find(element=> element==_sessionStorage.obj.role?true:false);

        if(!_response){

            Swal.fire({
                title: "Aviso",
                text: "Acceso no valido, cuenta de usuario sin permisos de acceso en sección actual.",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
            })

            if(_sessionStorage.obj.role == "member" || _sessionStorage.obj.role == "cabsa_member" ){
                return next({path:router.options.history.state.back})
            }

            if(_sessionStorage.obj.role == "administrator" || _sessionStorage.obj.role == "loan_analyst" || _sessionStorage.obj.role == "member_analyst" || _sessionStorage.obj.role=="supervisor" ){
                return next({path:router.options.history.state.back})
            }
        }

        renewToken();
    }
    next();
});

function invalidStoragesFound(path){
    const storaceList = [
        "issuance", 
        "quote", 
        "quote-data", 
        "issuance-applicant-address",  
        "issuance-data", 
        "quote-detail", 
        "document_ine",
        "quote-contact",
        "quote-medical-operations",
        "quote-smokes",
        "quote-coverage",
        "quote-sports",
        "quote-alcohol",
        "quote-personal-data",
        "quote-diseases",
        "quote-hobbies",
        "quote-recruitment-data",
        "quote-occupations",
        "quote-description",
        "issuance-address",
        "issuance-labor-information",
        "issuance-collection",
        "issuance-beneficiaries",
        "document_proof_of_address",
        "issuance-detail"
    ];
    const pathList = [
        '/cuenta/cotizacion/vida-simple', 
        '/cuenta/cotizacion/vida-simple/', 
        '/cuenta/cotizacion/vida-simple/bienvenida', 
        '/cuenta/cotizacion/vida-simple/asegurado-adicional', 
        '/cuenta/cotizacion/vida-simple/finalizar',
        "/cuenta/cotizacion/vida-simple/contratacion",
        "/cuenta/cotizacion/vida-simple/contacto",
        "/cuenta/cotizacion/vida-simple/datos-personales",
        "/cuenta/cotizacion/vida-simple/enfermedades",
        "/cuenta/cotizacion/vida-simple/deportes",
        "/cuenta/cotizacion/vida-simple/ocupacion",
        "/cuenta/cotizacion/vida-simple/operaciones",
        "/cuenta/cotizacion/vida-simple/pasatiempos",
        "/cuenta/cotizacion/vida-simple/fuma",
        "/cuenta/cotizacion/vida-simple/alcohol",
        "/cuenta/cotizacion/vida-simple/cobertura",
        "/cuenta/cotizacion/vida-simple/detalle",
        "/cuenta/cotizacion/vida-simple/cotizacion",
        '/cuenta/emision/vida-simple', 
        '/cuenta/emision/vida-simple/', 
        '/cuenta/emision/vida-simple/carga-documentos', 
        '/cuenta/emision/vida-simple/detalle', 
        '/cuenta/emision/vida-simple/finalizar',
        "/cuenta/emision/vida-simple/direccion",
        "/cuenta/emision/vida-simple/informacion-laboral",
        "/cuenta/emision/vida-simple/cobro",
        "/cuenta/emision/vida-simple/beneficiarios",
        '/cuenta/detalle/vida-simple/solicitante'];

    if(pathList.includes(path)){
        return;
    }
    
    storaceList.forEach(key => {
        localStorage.removeItem(localStorage.key(key));
    })
}


export default router