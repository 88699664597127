import { get } from 'axios'
const queryString = require('qs');

const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA

function getScript ( token_auth, script ) {
  const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/video_recording_script/get_by`
  const API_URL = `${host}${resource}`
  
  const config = {
    headers: {
      "Content-Type": "application/json",
    }
  };

  const params = {
    token_auth: token_auth,
    script: script
  }
  
  const paramsSerializer = params => {
    return queryString.stringify(params, {arrayFormat: 'brackets'});
  }

  return get(API_URL,  {params, paramsSerializer, config})
}

export {
  getScript
}