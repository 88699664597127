import { getScript } from "@/api/recording_script";

export default class Text {
  constructor(){

  }

  static getBy(token_auth, process_type, bussines_name, status, client_classification) {
    let script = {
      process_type: process_type,
      bussines_name: bussines_name,
      status: status,
      client_type: client_classification
    }

    return getScript(
      token_auth,
      script
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error
    }).finally(() => {
    })
  }

  static processScript(wordsToBeReplaced, script) {
    let processedScript = script;
    //processedScript = processedScript.replaceAll(/(\")/g, '');

    wordsToBeReplaced.forEach(element => {
      processedScript = processedScript.replaceAll(element.value, element.newValue);
    });

    return processedScript;
  }
}