const requestType = {
  "identidad_no_verificada": "Identidad no verificada",
  "pendiente_de_revision": "Pendiente de revisión",
  "en_revision": "En revisión",
  "con_informacion_validada": "Información validada",
  "rechazada": "Rechazada",
  "cancelada": "Cancelada",
  "en_espera_de_correcion_del_cliente": "En espera de correción del cliente",
  "incompleto": "Incompleto",
  "expirado": "Expirado",
  "asignada": "Asignada",
  "aceptada": "Aceptada",
  "info_personal_completa": "Información personal completa",
  "info_personal_incompleta": "Información personal incompleta",
  "info_economica_completa": "Información económica completa",
  "info_economica_incompleta": "Información económica incompleta",
  "info_laboral_completa": "Información laboral completa",
  "info_laboral_incompleta": "Información laboral incompleta",
  "info_ahorro_completa": "Información de ahorro completa",
  "info_ahorro_incompleta": "Información de ahorro incompleta",
  "info_beneficiarios_completa": "Información de beneficiarios completa",
  "info_beneficiarios_incompleta": "Información de beneficiarios incompleta",
  "carga_documento_completa": "Carga de documentos completa",
  "carga_documentos_incompleta": "Carga de documentos incompleta",
  "autorizo_descuento_aceptado": "Autorizo de descuento aceptado",
  "terminos_y_condiciones_aceptados": "Terminos y condiciones aceptados",
  "video_completo": "Videograbación completa"
}

const largeMonths = {
  "01": "Enero",
  "02": "Febrero",
  "03": "Marzo",
  "04": "Abril",
  "05": "Mayo",
  "06": "Junio",
  "07": "Julio",
  "08": "Agosto",
  "09": "Septiembre",
  "10": "Octubre",
  "11": "Noviembre",
  "12": "Diciembre"
};

const numericMonths = {
  "Enero": "01",
  "Febrero": "02",
  "Marzo": "03",
  "Abril": "04",
  "Mayo": "05",
  "Junio": "06",
  "Julio": "07",
  "Agosto": "08",
  "Septiembre": "09",
  "Octubre": "10",
  "Noviembre": "11",
  "Diciembre": "12"
};

export default {
  requestType: requestType,
  largeMonths: largeMonths,
  numericMonths: numericMonths
}