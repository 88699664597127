import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@/assets/css/header.css';
import '@/assets/css/fonts.css';
import '@/assets/tailwind.css';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import 'jquery';
import 'popper.js';
import 'animate.css';
import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker';
import './assets/css/app.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import Maska from 'maska';
import money from 'v-money3';

import Vuex from 'vuex';

import IdentificationRegister from 'basic-forms-vue/src/IdentificationRegister';
import IdentityVerification from 'basic-forms-vue/src/IdentityVerification';

import PersonalInformation from 'basic-forms-vue/src/DatosPersonales';
import BirthPlace from 'basic-forms-vue/src/BirthPlace';
import Contact from 'basic-forms-vue/src/Contact';
import MaritalStatus from 'basic-forms-vue/src/MaritalStatus';

import WorkingInformation from 'basic-forms-vue/src/WorkingInformation';
import SavingData from 'basic-forms-vue/src/SavingData';
import UploadMultipleFiles from 'basic-forms-vue/src/UploadMultipleFiles';
import Address from 'basic-forms-vue/src/Address';
import HousingInfo from 'basic-forms-vue/src/HousingInfo';
import Schooling from 'basic-forms-vue/src/Schooling';
import Beneficiaries from 'basic-forms-vue/src/Beneficiaries';
import VideoContract from 'basic-forms-vue/src/VideoContract';
import EconomicInformation from 'basic-forms-vue/src/EconomicInformation';
import DocumentControlList from 'basic-forms-vue/src/DocumentControlList';
import GifCardComponent from "basic-forms-vue/src/GifCardComponent";

import Ads from 'basic-forms-vue/src/Ads';
import CreditData from 'basic-forms-vue/src/CreditData';
import CreditOwner from 'basic-forms-vue/src/CreditOwner';
import DocumentHandler from 'basic-forms-vue/src/DocumentHandler';
import HistoricalLine from 'basic-forms-vue/src/HistoricalLine';
import DocumentsViewer from 'basic-forms-vue/src/DocumentsViewer';


import QuotationPersonalData from 'basic-forms-vue/src/Quotation/QuotationPersonalData.vue';
import QuotationHeightAndWeight from 'basic-forms-vue/src/Quotation/QuotationHeightAndWeight';
import QuotationCheckbtnTextSelect from 'basic-forms-vue/src/Quotation/QuotationCheckbtnTextSelect';
import QuotationTextSelect from 'basic-forms-vue/src/Quotation/QuotationTextSelect';
import QuotationTobaccoConsumption from 'basic-forms-vue/src/Quotation/QuotationTobaccoConsumption';
import QuotationAlcoholConsumption from 'basic-forms-vue/src/Quotation/QuotationAlcoholConsumption';
import QuotationCoverage from 'basic-forms-vue/src/Quotation/QuotationCoverage';
import QuotationContactInformation from 'basic-forms-vue/src/Quotation/QuotationContactInformation';

import IssuanceAddress from 'basic-forms-vue/src/Issuance/IssuanceAddress';
import IssuanceCollection from 'basic-forms-vue/src/Issuance/IssuanceCollection';
import IssuanceLaborInformation from 'basic-forms-vue/src/Issuance/IssuanceLaborInformation';
import IssuanceBeneficiaries from 'basic-forms-vue/src/Issuance/IssuanceBeneficiaries';



import vSelect from 'vue-select';
import { formatDate, formatCurrency, formatCurrencyWithDecimal, formatPhone, formatUppercase, formatCamelize, formatCurrentDateName, formatCurrentDateHour, requestStatus, formatDateComplete, formatMeters, formatCapitalize, unformatCurrency, formatDateByPeriod } from '@/helpers/filters.js';

import InputReactiveForm from 'input-reactive-for-vue'

import VueDatePickerNew from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)
  .use(router)
  .use(store)
  .use(VueSweetalert2)
  .use(Vuex)
  .use(money)
  .use(VueAxios, axios)
  .use(Maska)
  .use(vSelect,Vue3PersianDatetimePicker)

app.component('InputReactiveFormSimple', InputReactiveForm)
app.component("v-select", vSelect);
app.component("DatePicker", Vue3PersianDatetimePicker);

app.component('IdentificationRegister', IdentificationRegister);
app.component('IdentityVerification', IdentityVerification);

app.component('PersonalInformation', PersonalInformation);
app.component('BirthPlace', BirthPlace);
app.component('Contact', Contact);
app.component('MaritalStatus', MaritalStatus);
app.component('EconomicInformation', EconomicInformation);

app.component('WorkingInformation', WorkingInformation);
app.component('SavingData', SavingData);
app.component('UploadMultipleFiles', UploadMultipleFiles);
app.component('Address', Address);
app.component('HousingInfo', HousingInfo)
app.component('Schooling', Schooling);
app.component('Beneficiaries', Beneficiaries);
app.component('VideoContract', VideoContract);
app.component('DocumentControlList', DocumentControlList);

app.component('AdsComponent', Ads)
app.component('CreditData', CreditData)
app.component('CreditOwner', CreditOwner)
app.component('DocumentHandler', DocumentHandler)
app.component('HistoricalLine', HistoricalLine)
app.component('GifCardComponent', GifCardComponent)


app.component('DocumentsViewer', DocumentsViewer)



app.component('QuotationPersonalDataComponent', QuotationPersonalData);
app.component('QuotationHeightAndWeightComponent', QuotationHeightAndWeight);
app.component('QuotationCheckbtnTextSelectComponent', QuotationCheckbtnTextSelect);
app.component('QuotationTextSelectComponent', QuotationTextSelect);
app.component('QuotationTobaccoConsumptionComponent', QuotationTobaccoConsumption);
app.component('QuotationAlcoholConsumptionComponent', QuotationAlcoholConsumption);
app.component('QuotationCoverageComponent', QuotationCoverage);
app.component('QuotationContactInformationComponent', QuotationContactInformation);

app.component('IssuanceAddressComponent', IssuanceAddress);
app.component('IssuanceCollectionComponent', IssuanceCollection);
app.component('IssuanceLaborInformationComponent', IssuanceLaborInformation);
app.component('IssuanceBeneficiariesComponent', IssuanceBeneficiaries);


app.mount('#app');

app.component('VueDatePickerNew', VueDatePickerNew);

app.config.globalProperties.$filters = { formatDate, formatCurrency, formatCurrencyWithDecimal, formatPhone, formatUppercase, formatCamelize, formatCurrentDateName, formatCurrentDateHour, requestStatus, formatDateComplete, formatMeters, formatCapitalize, unformatCurrency, formatDateByPeriod };