
<template>
  <html lang="en" class="notranslate" translate="no"><!-- All translators -->
      <head>
        <meta name="google" content="notranslate" /> <!-- Just for google -->
        <meta name="keywords" content="SINAPTISSSTE, FONCABSA, SOCAP, Sociedad Cooperativa, Ahorro, Préstamo"/>
        <meta name="description" content="FONCABSA es una sociedad cooperativa de ahorro y préstamo, que ofrece beneficios a corto, mediano y largo plazo."/>
        <!-- <meta name="description" content=""/> -->
    </head>
    <div id="app">
      <transition name="fade">
        <router-view/>
      </transition>
    </div>
  </html>
</template>

<script>
import { onMounted } from 'vue-demi';
import {useRouter} from "vue-router";
import {minuteExpiration,getTokenDecoden,isAuth,renewToken} from "@/helpers/tokenauth";
import Swal from 'sweetalert2';
export default{
  setup(){
    const router = useRouter()
    
    onMounted(()=>{
      setInterval(()=>{
      evaluationSession();
      },59999)
    })

    function evaluationSession(){
        const x=minuteExpiration();
        if(x){
          let data=getTokenDecoden();
          if(data.obj["role"]=="issste_enroller" || data.obj["role"]=="administrator" || data.obj["role"]=="loan_analyst" || data.obj["role"]=="member_analyst" || data.obj["role"]=="enroller" || data.obj["role"]=="supervisor"){
            renewToken();
          }else{
            Swal.fire({
              title: 'Atención',
              text: "Su sesión actual esta por caducar. ¿Desea continuar en el sistema?.",
              icon: 'warning',
              showCancelButton: true,
              reverseButtons:true,
              confirmButtonColor: '#FEB72B',
              cancelButtonColor: '#FFFFFF',
              confirmButtonText: 'Continuar',
              cancelButtonText:"Cancelar",
              allowOutsideClick:false,
              timer:15000
            }).then((result) => {
              if (result.isConfirmed) {
                renewToken();
                const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              Toast.fire({
                icon: 'success',
                title: 'Su sesión fue renovada '
              })
              }
            })
            setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_delete', 'button_cancel_delete') 
            }
        }else{
          if((isAuth())==false && sessionStorage.getItem("login")!=null){
            sessionStorage.clear();
            router.push({path:"/"});
          }
        }
    }

    function  setIdAttributeToAccepAndCancelButtonSwalFire(idButtonConfirm, idButtonCancel) {
      $('.swal2-confirm').attr('id', idButtonConfirm);
      $('.swal2-cancel').attr('id', idButtonCancel);
    }
  }
}
</script>

<style>

  #texto{
    text-decoration-color: white;
  }

  .button{
    background-color: Transparent !important;
    background-repeat:no-repeat !important;
    border: none !important;
    cursor:pointer !important;
    overflow: hidden !important;
    outline:none !important;
    color:white !important;
    font-size: 17px !important;
  }

  
</style>


<style>
.menu {
  float:left;
  width:20%;
  text-align:center;
}
.menu a {
  background-color:#e5e5e5;
  padding:8px;
  margin-top:7px;
  display:block;
  width:100%;
  color:black;
}
.main {
  float:left;
  width:60%;
  padding:0 20px;
}
.right {
  background-color:#e5e5e5;
  float:left;
  width:20%;
  padding:15px;
  margin-top:7px;
  text-align:center;
}

@media only screen and (max-width:620px) {
  /* For mobile phones: */
  .menu, .main, .right {
    width:100%;
  }
}

#button_cancel_delete{
    color: #810042 !important;
    border: 1px solid #810042;
}


#button_cancel_delete:hover{
    color: white !important;
    border: 1px solid #810042;
    background: #810042 !important;
}


.vpd-actions{
  direction: rtl !important;
}

.vpd-actions > button:nth-child(3){
  float:right;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .10s
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0
}
</style>

