import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    openLateralMenuAccount: true,
    openMobileMenuAccount: false,
    forwardingCertificatesStatus: 0,
    availableForRegistration: false,
    countMissingData: [],
    isCandidate: false,
  },
  mutations: {
    setOpenLateralMenuAccount(state, { value }) {
      state.openLateralMenuAccount = value;
    },
    setOpenMobileMenuAccount(state, { value }) {
      state.openMobileMenuAccount = value;
    },
    setAvailableForRegistration(state, { value }) {
      state.availableForRegistration = value
    },
    setForwardingCertificatesStatus(state, { value }){
      state.forwardingCertificatesStatus = value;
    },
    setCountMissingData(state, { value }){
      state.countMissingData = value;
    },
    setIsCandidate(state, { value }){
      state.isCandidate = value;
    }
  },
  actions: {
    setOpenLateralMenuAccountAction(context, value) {
      context.commit('setOpenLateralMenuAccount', { value });
    },
    setOpenMobileMenuAccountAction(context, value) {
      context.commit('setOpenMobileMenuAccount', { value });
    },
    setAvailableForRegistration(context, value) {
      context.commit('setAvailableForRegistration', value);
    },
    setForwardingCertificatesStatusAction(context, value){
      context.commit('setForwardingCertificatesStatus', { value });
    },
    setCountMissingDataAction(context, { value }){
      context.commit('setCountMissingData', { value });
    },
    setIsCandidate(context, { value }){
      context.commit('setIsCandidate', { value });
    }
  },
  getters: {
    getOpenLateralMenuAccount(state){
      return state.openLateralMenuAccount;
    },
    getOpenMobileMenuAccount(state){
      return state.openMobileMenuAccount;
    },
    getAvailableForRegistration(state){
      return state.availableForRegistration;
    },
    getForwardingCertificatesStatus(state){
      return state.forwardingCertificatesStatus;
    },
    getCountMissingDataNumber(state){
      return state.countMissingData;
    },
    getIsCandidate(state){
      return state.isCandidate;
    }
  },
  modules: {
  },
  plugins: [createPersistedState()]
})