import RecorderScript from '@/classes/RecorderScript';
import { upload, getVideo } from "@/api/video_recorder";

export default class Video {
  id = 0;
  recordingBase64 = "";
  name = "";
  extension = "";
  recordingLength = 0; //Seconds
  text = "";

  startTime = "";
  mediaRecorder = "";
  idInterval = "";
  urlVideo = "";

  get getRecordingLength() {
    return this.recordingLength;
  }

  get getRecordingBase64() {
    return this.recordingBase64;
  }
  
  get getStartTime() {
    return this.startTime;
  }

  set setStartTime(startTime) {
    this.startTime = startTime;
  }

  constructor() {
    this.recordingLength = 42;
  }

  startRecording = () => {
    if (this.mediaRecorder) throw "Ya se está grabando";

    const $video = document.querySelector("#video");
    const $dispositivosDeAudio = document.querySelector("#dispositivosDeAudio");
    const $dispositivosDeVideo = document.querySelector("#dispositivosDeVideo");

    return navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: $dispositivosDeAudio.value, // Indicar dispositivo de audio
        },
        video: {
          deviceId: $dispositivosDeVideo.value, // Indicar dispositivo de vídeo
          // width: { min: 720 },
          // height: { min: 720 }
        }
    })
    .then(stream => {
        // Poner stream en vídeo
        $video.srcObject = stream;
        $video.play();
        // Comenzar a grabar con el stream
        this.mediaRecorder = new MediaRecorder(stream);
        var lt = this.mediaRecorder;
        this.mediaRecorder.start();
        //comenzarAContar();
        // En el arreglo pondremos los datos que traiga el evento dataavailable
        const fragmentosDeAudio = [];
        // Escuchar cuando haya datos disponibles
        this.mediaRecorder.addEventListener("dataavailable", evento => {
            // Y agregarlos a los fragmentos
            fragmentosDeAudio.push(evento.data);
        });
        // Cuando se detenga (haciendo click en el botón) se ejecuta esto
        this.mediaRecorder.addEventListener("stop", () => {
            // Pausar vídeo
            $video.pause();
            // Detener el stream
            stream.getTracks().forEach(track => track.stop());
            // Detener la cuenta regresiva
            //detenerConteo();
            // Convertir los fragmentos a un objeto binario
            const blobVideo = new Blob(fragmentosDeAudio, {type: 'video/mp4'});

            // const formData = new FormData();

            // // Enviar el BinaryLargeObject con FormData
            // formData.append("video", blobVideo);
            this.extension = blobVideo.type.split('/')[1];
            this.urlVideo = URL.createObjectURL(blobVideo);
            this.serialize(this.urlVideo);
            // var video=document.getElementById('video2')
            // video.src = URL.createObjectURL(blobVideo);
            // console.log(blobVideo);
        });

        return true;
    })
    .catch(error => {
        return false;
    });
  }

  getRecorderScript(token_auth, process_type, bussines_name, status, client_classification) {
    if(process_type == "" || process_type == null) throw "nombre del proceso no puede ir vacío o nulo"
    if(client_classification == "" || client_classification == null) throw "clasificación del cliente no puede ir vacío o nulo"
    if(bussines_name == "" || bussines_name == null) throw "nombre del negocio no puede ir vacío o nulo"

    return RecorderScript.getBy(token_auth, process_type, bussines_name, status, client_classification).then((script) => {
      let recorderScript = script.data.response.recording_script[0].script;
      if(recorderScript == "" || recorderScript == null || recorderScript == undefined) throw "No existe texto registrado para este proceso. Favor de contactar a sistemas."

      return recorderScript;
    }).catch((error) => {
      throw "No existe texto registrado para este proceso. Favor de contactar a sistemas.";
    });
  }

  getProcessedScript = (wordsToBeReplaced, script) => {
    return RecorderScript.processScript(wordsToBeReplaced, script);
  }

  serialize = (url) => {
    let self = this;
    return fetch(url).then(data=>{
      const blob = data.blob();
      return blob;
    }).then(blob=>{
      let reader = new window.FileReader();
      
      reader.onloadend = function() {
        self.recordingBase64 = reader.result.split(',')[1];
      };
   
      reader.readAsDataURL(blob);
    })
  }

  uploadVideo = (token_auth, name, process_name, client_id = "") => {
    let video_list=[];
    video_list.push({
      name: name,
      extension: this.extension,
      process_name: process_name,
      file: this.recordingBase64
    });
    console.log(video_list);
    return upload(
      token_auth,
      video_list,
      client_id
    )
    .then(({data}) => {
    })
    .catch((error) => {
    }).finally(() => {
    })
  }
  stopRecording = () => {
    if (!this.mediaRecorder) {
      throw "La grabación no se ha iniciado"
    } 
    this.mediaRecorder.stop();
    this.mediaRecorder = null;
  }

  getVideo = (token_auth, admission_id) => {
    let video_list = [
      { 
        videorecordingtable_id: admission_id,
        remote_folder:"documentos_alta_socios"
      }
    ];
    
    return getVideo(
      token_auth,
      video_list
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error
    }).finally(() => {
    })
  }

  getRecordingTime = (seconds) => {
    let hours = Math.floor(seconds / 60 / 60);
    seconds -= hours * 60 * 60;

    let minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    seconds = parseInt(seconds);
    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;

    return `${hours}:${minutes}:${seconds}`;
  }

  getSecondsVideoRecording() {
    return (Date.now() - this.startTime) / 1000;
  }
}