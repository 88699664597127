import { get, post } from 'axios'
const queryString = require('qs');

const protocol = 'https://'
const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA

function getVideo ( token_auth, video_list ) {
  const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/download_video_recording`
  const API_URL = `${host}${resource}`
  
  const config = {
    headers: {
      "Content-Type": "application/json",
    }
  };

  const params = {
    token_auth: token_auth,
    video_list: video_list
  }

  // const paramsSerializer = params => {
  //   return queryString.stringify(params, {arrayFormat: 'brackets'});
  // }
  
  return post(API_URL,  params, config)
}

function upload ( token_auth, video_list, client_id= "" ) {
  const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/register_video_recording`
  const API_URL = `${host}${resource}`
  
  const config = {
    headers: {
      "Content-Type": "application/json",
    }
  };

  const params = {
    token_auth: token_auth,
    video_list: video_list,
    client_id: `${client_id}`
  }
  
  return post(API_URL,  params, config )
}

export {
  upload,
  getVideo,
}