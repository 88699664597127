import moment from 'moment';
import api from '@/api/api';
import { formatCapitalize, formatUpperCaseFirst } from '@/helpers/filters';

function isValidJwt(){
    const _sessionStorage=sessionStorage.getItem("login");
    if(_sessionStorage!=null){
        const _JWT=decodeToken(_sessionStorage);
        const d = new Date(0);
        d.setUTCSeconds(_JWT.exp);
        return (d.getTime())<(new Date().getTime())?false:true;
    }
    return false;
}

function decodeToken (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

function getTokenDecoden(){
    const _sessionStorage = sessionStorage.getItem("login");
    if(_sessionStorage != null){
        const _JWT = decodeToken(_sessionStorage);
        return _JWT;
    }
    return _sessionStorage;
}

function isAuth(){
    return isValidJwt();
}

function minuteExpiration(){
    const _expiration=isValidJwt();
    if(_expiration){
        const _JWT=decodeToken(sessionStorage.getItem("login"));
        const _date = new Date(0);
        _date.setUTCSeconds(_JWT.exp);
        const _dateActu=new moment()
        const _newDate=new moment(_date)
        const diff = moment.duration(moment(_newDate).diff(moment(_dateActu)));
        const days = parseInt(diff.asDays());
        var hours = parseInt(diff.asHours());
        hours = hours - (days*24);
        var minutes = parseInt(diff.asMinutes());
        minutes = minutes - ((days*24*60) + (hours*60));
        // console.log("Tiempo para expirar ::: "+minutes)
        return minutes==0||minutes==1?true:false;
    }
    return false;
}

function renewToken(){
    api.httpGetRefreshToken();
}

function getUserInfo() {
    let auth = getTokenDecoden();
    const accountType = auth.obj['role'];
    let account = "";
    let role="";
    let client_id=0;
    switch (auth.obj['role']){
      case "administrator":
        account = "admin";
        role="Administrador";
      break;
      case "member":
        account = "cuenta";
        role="Cliente";
      break;
      case "member_analyst":
        account = "analyst";
        role="Analista de clientes";
      break;
      case "cabsa_member":
        account = "cuenta";
        role="Cliente";
      break;
      case "supervisor":
        account = "supervisor";
        role="Supervisor";
      break;
      case "loan_analyst":
        account = "analyst";
        role="Analista de prestamos";
      break;
      case "enroller": 
        account="adviser";
      break;
      case "issste_enroller":
        account="sinaptissste-enroller";
        role="sinaptissste Inscripción";
      break;
    }

    let fullName = "";
    let shortName = "";
    if(auth.obj.personnel_info){
      fullName = `${auth.obj.personnel_info["name"]} ${auth.obj.personnel_info["middle_name"]} ${auth.obj.personnel_info["paternal_name"]} ${auth.obj.personnel_info["maternal_name"]}`;
      shortName = `${auth.obj.personnel_info["name"]} ${auth.obj.personnel_info["middle_name"]} ${auth.obj.personnel_info["paternal_name"]}`;
    }else if(auth.obj.person){
      fullName = `${auth.obj.person["name"]} ${auth.obj.person["middle_name"]} ${auth.obj.person["paternal_name"]} ${auth.obj.person["maternal_name"]}`;
      shortName = `${auth.obj.person["name"]} ${auth.obj.person["middle_name"]} ${auth.obj.person["paternal_name"]}`;
      client_id = auth.obj.person.client_id == undefined ? 0 : auth.obj.person.client_id
    }
  
    var list_names =  fullName.split(" ");
    var name = "";
    for (var i = 0; i < list_names.length; i+=1) {
      name += formatUpperCaseFirst(list_names[i])+" ";
    }
    
    fullName=name;

    return {
        'full_name': fullName,
        'short_name': shortName,
        'account': account,
        'account_type': accountType,
        'role':role,
        'client_id':client_id
    }
}

function getToken() {
  return sessionStorage.getItem('login');
}

export {
  isValidJwt,
  isAuth,
  getTokenDecoden,
  minuteExpiration,
  renewToken,
  getUserInfo,
  getToken
}
