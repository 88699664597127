import { get, post } from 'axios'
const queryString = require('qs');

const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA

function sendReferal ( token_auth, fullname, code, email, cellphone, user_id ) {
  const resource = `/${business}/send_referal`
  const API_URL = `${host}${resource}`
  
  const config = {
    headers: {
      "Content-Type": "application/json",
    }
  };

  const params = {
    token_auth: token_auth,
    name: fullname,
    reference_code: code,
    email: email,
    cellphone: cellphone,
    fonsnte_user_id: user_id,
  }


  return post(API_URL,  params, config )
}

function generateReference ( token_auth, reference ) {
  const resource = `/${business}/generate_references`
  const API_URL = `${host}${resource}`
  
  const config = {
    headers: {
      "Content-Type": "application/json",
    }
  };

  const params = {
    token_auth: token_auth,
    type_reference: reference
  }


  return post(API_URL,  params, config )
}

function getStats ( token_auth, user_id ) {
  const resource = `/${business}/referals/get_stats`
  const API_URL = `${host}${resource}`
  
  const config = {
      headers: {
          "Content-Type": "application/json",
      }
  };

  const params = {
    token_auth: token_auth,
    user_id: user_id
  }
  
  return get(API_URL, { params, config } ) 
}

function getNoSent ( token_auth, user_id ) {
  const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/referals/get_not_sent`
  const API_URL = `${host}${resource}`
  
  const config = {
      headers: {
          "Content-Type": "application/json",
      }
  };

  const params = {
    token_auth: token_auth,
    id: user_id
  }
  
  return get(API_URL, { params, config } ) 
}

function getSent ( token_auth, user_id ) {
  const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/referals/get_sent`
  const API_URL = `${host}${resource}`
  
  const config = {
      headers: {
          "Content-Type": "application/json",
      }
  };

  const params = {
    token_auth: token_auth,
    id: user_id
  }
  
  return get(API_URL, { params, config } ) 
}

function getIncompleteRequest ( token_auth, user_id ) {
  const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/referals/get_incomplete_request`
  const API_URL = `${host}${resource}`
  
  const config = {
      headers: {
          "Content-Type": "application/json",
      }
  };

  const params = {
    token_auth: token_auth,
    id: user_id
  }
  
  return get(API_URL, { params, config } ) 
}

function getAcceptedRequest ( token_auth, user_id ) {
  const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/referals/get_accepted_request`
  const API_URL = `${host}${resource}`
  
  const config = {
      headers: {
          "Content-Type": "application/json",
      }
  };

  const params = {
    token_auth: token_auth,
    id: user_id
  }
  
  return get(API_URL, { params, config } ) 
}

function getWithAccountCreated ( token_auth, user_id ) {
  const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/referals/get_account_created`
  const API_URL = `${host}${resource}`
  
  const config = {
      headers: {
          "Content-Type": "application/json",
      }
  };

  const params = {
    token_auth: token_auth,
    id: user_id
  }
  
  return get(API_URL, { params, config } ) 
}

function getWithAdmissionRequestIncomplete ( token_auth, user_id ) {
  const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/referals/get_incomplete_request`
  const API_URL = `${host}${resource}`
  
  const config = {
      headers: {
          "Content-Type": "application/json",
      }
  };

  const params = {
    token_auth: token_auth,
    id: user_id
  }
  
  return get(API_URL, { params, config } ) 
}

function getWithAdmissionRequestUnderReview ( token_auth, user_id ) {
  const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/referals/get_complete_request`
  const API_URL = `${host}${resource}`
  
  const config = {
      headers: {
          "Content-Type": "application/json",
      }
  };

  const params = {
    token_auth: token_auth,
    id: user_id
  }
  
  return get(API_URL, { params, config } ) 
}

function getWithAdmissionRequestRejected ( token_auth, user_id ) {
  const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/referals/get_rejected_request`
  const API_URL = `${host}${resource}`
  
  const config = {
      headers: {
          "Content-Type": "application/json",
      }
  };

  const params = {
    token_auth: token_auth,
    id: user_id
  }
  
  return get(API_URL, { params, config } ) 
}

function getAllByUserId ( token_auth, user_id ) {
  const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/referals/get_all_by_user_id`
  const API_URL = `${host}${resource}`
  
  const config = {
      headers: {
          "Content-Type": "application/json",
      }
  };

  const params = {
    token_auth: token_auth,
    id: user_id
  }
  
  return get(API_URL, { params, config } ) 
}

function getOpenLink ( token_auth, user_id ) {
  const resource = `/${business}/referals/get_open_link`
  const API_URL = `${host}${resource}`
  
  const config = {
      headers: {
          "Content-Type": "application/json",
      }
  };

  const params = {
    token_auth: token_auth,
    id: user_id
  }
  
  return get(API_URL, { params, config } ) 
}

function openReferalLink ( referal_id ) {
  const resource = `/${business}/referals/open_link`
  const API_URL = `${host}${resource}`
  
  const config = {
    headers: {
      "Content-Type": "application/json",
    }
  };

  const params = {
    referal_id: referal_id
  }


  return post(API_URL,  params, config )
}

function getHistoryReportToPromoter ( token_auth, user_id ) {
  const resource = `/${business}/referals/get_history_report_to_promoter`
  const API_URL = `${host}${resource}`
  
  const config = {
      headers: {
          "Content-Type": "application/json",
      }
  };

  const params = {
    token_auth: token_auth,
    user_id: user_id
  }
  
  return get(API_URL, { params, config } ) 
}

export {
  sendReferal,
  generateReference,
  getStats,
  getNoSent,
  getSent,
  getIncompleteRequest,
  getAcceptedRequest,
  getAllByUserId,
  getWithAccountCreated,
  getWithAdmissionRequestIncomplete,
  getWithAdmissionRequestUnderReview,
  getWithAdmissionRequestRejected,
  getOpenLink,
  openReferalLink,
  getHistoryReportToPromoter
}