const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA
import api from '@/api/api'
import { getNoSent,
          getSent,
          getAcceptedRequest,
          getAllByUserId,
          getWithAccountCreated,
          getWithAdmissionRequestIncomplete,
          getWithAdmissionRequestUnderReview,
          getWithAdmissionRequestRejected,
          getOpenLink,
          openReferalLink } from '@/api/references'


export default class Referral {

  constructor() {

  }

  getNoSent = (tokenAuth, userId) => {
    return getNoSent(tokenAuth, userId).then(response => {
      return response;
    }).catch(error => {
      throw error;
    })
  }

  getSent = (tokenAuth, userId) => {
    return getSent(tokenAuth, userId).then(response => {
      return response;
    }).catch(error => {
      throw error;
    })
  }

  getAcceptedRequest = (tokenAuth, userId) => {
    return getAcceptedRequest(tokenAuth, userId).then(response => {
      return response;
    }).catch(error => {
      throw error;
    })
  }

  getAllByUserId = (tokenAuth, userId) => {
    return getAllByUserId(tokenAuth, userId).then(response => {
      return response;
    }).catch(error => {
      throw error;
    })
  }

  getWithAccountCreated = (tokenAuth, userId) => {
    return getWithAccountCreated(tokenAuth, userId).then(response => {
      return response;
    }).catch(error => {
      throw error;
    })
  }

  getWithAdmissionRequestIncomplete = (tokenAuth, userId) => {
    return getWithAdmissionRequestIncomplete(tokenAuth, userId).then(response => {
      return response;
    }).catch(error => {
      throw error;
    })
  }

  getWithAdmissionRequestUnderReview = (tokenAuth, userId) => {
    return getWithAdmissionRequestUnderReview(tokenAuth, userId).then(response => {
      return response;
    }).catch(error => {
      throw error;
    })
  }

  getWithAdmissionRequestRejected = (tokenAuth, userId) => {
    return getWithAdmissionRequestRejected(tokenAuth, userId).then(response => {
      return response;
    }).catch(error => {
      throw error;
    })
  }

  getOpenLink = (tokenAuth, userId) => {
    return getOpenLink(tokenAuth, userId).then(response => {
      return response;
    }).catch(error => {
      throw error;
    })
  }

  openReferalLink = (referal_id) => {
    return openReferalLink(referal_id).then(response => {
      return response;
    }).catch(error => {
      throw error;
    })
  }

  getHistoryReportToPromoter = (promoter_id) => {
    return getHistoryReportToPromoter(promoter_id).then(response => {
      return response;
    }).catch(error => {
      throw error;
    })
  }

  get = async (bodyContent, url) => {
    const apiUrl = `${host}/${process.env.VUE_APP_BUSINESS_FONCABSA}/${url}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    const params = bodyContent;
    try {
      return await api.httpGet(apiUrl, config, bodyContent); 
    } catch (error) {
      throw error.response;
    }
  }

  post = async (commissionScheme, url) => {
    const API_URL = `${host}/${process.env.VUE_APP_BUSINESS_FONCABSA}/${url}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    const params = commissionScheme;

    return await api.httpPost(API_URL, config, params).catch(err => { return err.response });
  }
}