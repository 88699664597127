import Swal from 'sweetalert2';

const setIdAttributeToButtons = (firstButtonId, secondButtonId) => {
  if (firstButtonId != "") {
    $('.swal2-confirm').attr('id', firstButtonId);  
  }
  
  if (secondButtonId != "") {
    $('.swal2-cancel').attr('id', secondButtonId); 
  }
}

const setIdAttributeToModalWindow = (modalId) => {
  $('.swal2-container').attr('id', modalId);
}

const showMessage = (type, title, message, confirmButtonText = "OK", confirmButtonColor= "#FEB72B") =>{
  Swal.fire({
      title: title,
      html: message,
      icon: type,
      confirmButtonText: confirmButtonText,
      confirmButtonColor: confirmButtonColor
  });
}

export default {
  setIdAttributeToButtons,
  setIdAttributeToModalWindow,
  showMessage
}