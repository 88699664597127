import {get, post } from 'axios'
import {minuteExpiration} from "@/helpers/tokenauth"

const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA

async function httpGet(apiUrl, config = {}, params = {})  {
    // console.log(apiUrl.includes("token_auth"));
    if(JSON.stringify(params).includes("token_auth")){
        await httpGetRefreshToken();
    }
    return get(apiUrl, { params, config })
}

function httpPost(apiUrl, config, params) {
    // console.log(JSON.stringify(params).includes("token_auth"));
    return post(apiUrl, params, config)
}


async function httpGetRefreshToken()  {
    if((await minuteExpiration())){
        refresh_token();
    } 
}

async function refresh_token()
{
    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };
    const params = {};
    const _response=await get(host+`/${business}/token/generate?token_auth=`+sessionStorage.getItem("login"), { params, config });
    if(_response.data.response.has_errors==false){
        sessionStorage.setItem("login",_response.data.response.token_auth)
    }
}


export default {
    httpGet: httpGet,
    httpPost: httpPost,
    httpGetRefreshToken:httpGetRefreshToken,
    refresh_token:refresh_token
}