// import moment from 'moment';
import moment from 'moment-timezone'
import Referal from '@/classes/Referral'
import enums from '@/helpers/enums';
import { format, unformat } from 'v-money3';
import { mask } from 'maska';

function referalStatus(value) {
  if (value != "" && value != undefined) {
    return Referal.referalStatus[value];
  }
  return value;
};

function requestStatus(value) {
  if (value != "" && value != undefined) {
    return enums.requestType[value];
  }
  return value;
};

function formatDate(date) {
  if (date != "" && date != undefined && moment(date).tz('America/Hermosillo').isValid())
    return moment(date).tz('America/Hermosillo').format("DD/MM/YYYY");
  return date;
};

function formatDateComplete(date) {
  moment.locale('es');
  if (date != "" && date != undefined && moment(date).tz('America/Hermosillo').isValid())
    return moment(date).tz('America/Hermosillo').format("LL");
  return date;
};

function unformatCurrency(value) {
  return value.replace('$', '');
};

function formatCurrency(value) {
  let config = {
    decimal: ".",
    thousands: ",",
    prefix: "$",
    suffix: "",
    precision: 0,
    masked: false /* doesn't work with directive */,
  };

  return format(value, config);
};

function formatCurrencyWithDecimal(value) {
  let config = {
    decimal: ".",
    thousands: ",",
    prefix: "$",
    suffix: "",
    precision: 2,
    masked: false /* doesn't work with directive */,
  };

  return format(value, config);
};

function formatPhone(value) {
  return mask(value, '### ###-####')
};

function formatUppercase(value) {
  if (value != "" && value != undefined) {
    value = value.toUpperCase();
  }
  return value;
};

function formatCamelize(str) {
  let response = ""
  if (str != "" && typeof str != 'undefined') {
    str = str.toString().toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, function (match) {
      return match.charAt(match.length - 1).toUpperCase();
    });
    response = str.charAt(0).toUpperCase() + str.substring(1);
    return response.replace(/([A-Z])/g, ' $1').trim();
  }
  return "";
}

function formatCurrentDateName(date) {
  let self = this;
  let dateToday = new Date(date);
  let year = dateToday.toLocaleDateString('es-MX', { year: 'numeric' });
  let monthName = dateToday.toLocaleDateString('es-MX', { month: 'short' }).replaceAll(".", "");
  let month = String(dateToday.getMonth() + 1).padStart(2, '0');
  let day = dateToday.toLocaleDateString('es-MX', { day: 'numeric' });

  return `${day} ${monthName} ${year.substr(-2)}`
}

function formatCurrentDateHour(date) {
  let dateToday = new Date(date);

  return (dateToday != "Invalid date") ? `${dateToday.getHours()}:${dateToday.getMinutes()}` : "--";
}

function formatCapitalize(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

function formatUpperCaseFirst(text) {
  if (text != "undefined" && text != null && text != "") {
    return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
  }
  return ""
}

function formatMeters(text) {
  let mts = text * 0.01;
  return `${mts.toFixed(2)} Mts`;
}

function formatDateByPeriod(date){
  const split = date.toString().split('-');
  return `${split[1]} de ${split[0]}`;
}

export {
  formatDate,
  formatCurrency,
  formatPhone,
  formatUppercase,
  formatCamelize,
  formatCurrentDateName,
  formatCurrentDateHour,
  requestStatus,
  formatCapitalize,
  formatUpperCaseFirst,
  referalStatus,
  formatDateComplete,
  formatCurrencyWithDecimal,
  formatMeters,
  unformatCurrency,
  formatDateByPeriod
};